import Enum from './_Enum';

let Universe = {

  /** Irradiation */

  SR: {
    id: 'SR',
    name: 'Source',
    category: 'Équipements',
    hasBoard: true
  },

  DM: {
    id: 'DM',
    name: 'Dosimètre',
    category: 'Équipements',
    hasBoard: true
  },

  RM: {
    id: 'RM',
    name: 'Radiamètre',
    category: 'Équipements',
    hasBoard: true
  },

  EP: {
    id: 'EP',
    name: 'Écran de protection',
    category: 'Équipements',
    hasBoard: false,

    // Children informations
    children: {
      kind: "BE",
      count: 2
    }
  },

  BE: {
    id: 'BE',
    name: 'Balise écran',
    category: 'Équipements',
    hasBoard: true,

    // Parent informations
    parent: {
      kind: "EP"
    }
  },

  TS: {
    id: 'TS',
    name: 'Perche téléscopique',
    category: 'Accessoires',
    hasBoard: true
  },

  /** Contamination */

  // CM: {
  //   id: 'CM',
  //   name: 'Contaminamètre',
  //   category: 'Équipements',
  //   hasBoard: true
  // },

  BR: {
    id: 'BR',
    name: 'Balise radiamétrique',
    category: 'Accessoires'
  },

  /** Conditioning */

  VA: {
    id: 'VA',
    name: 'Valise',
    category: 'Conditionnement',
    hasBoard: false
  },

  RC: {
    id: 'RC',
    name: 'Rack de chargement',
    category: 'Conditionnement',
    hasBoard: false
  }

};

export default Enum(Universe, null, (id, values) => {
  return values.find(kind => kind.id === id);
});
